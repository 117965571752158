@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply h-screen;
  }
  body {
    @apply min-h-screen text-text-body;
  }
  h1 {
    @apply mb-2 text-2xl text-text-heading md:mb-6;
  }
  h2 {
    @apply mb-2 text-xl text-text-heading;
  }
  h3 {
    @apply mb-2 text-text-heading;
  }
  a {
    @apply select-text hover:underline;
  }
  label {
    @apply text-text-heading;
  }
  input {
    @apply text-text-heading;
  }
  table {
    @apply w-full text-text-heading;
  }
  tr {
    @apply select-text;
  }
  tr td {
    @apply bg-background-base;
  }
  tr:nth-child(odd) td {
    @apply bg-background-neutral;
  }
  th {
    @apply bg-background-base p-4 text-left text-sm font-semibold;
  }
  td {
    @apply select-text px-4 py-3 text-left text-sm font-normal;
  }
  progress {
    @apply h-[24px] w-full rounded-lg border;
  }
  progress[value]::-webkit-progress-bar {
    @apply rounded-lg bg-background-neutral;
  }
  progress[value]::-webkit-progress-value {
    @apply rounded-lg bg-primary transition-all;
  }
}

@layer components {
  table tr {
    @apply select-text;
  }
  .btn {
    @apply flex min-h-[40px] items-center justify-center rounded-lg border border-transparent px-4 py-2 transition-all hover:no-underline hover:opacity-50 disabled:cursor-not-allowed disabled:opacity-50;
  }
  .btn-small {
    @apply min-h-[32px]  py-1;
  }
  .btn-primary {
    @apply bg-primary text-primary-contrast hover:enabled:bg-primary-dark hover:enabled:opacity-100;
  }
  .btn-primary svg {
    @apply fill-primary-contrast;
  }
  .btn-primary-outlined {
    @apply border-2 border-primary bg-primary-light-contrast text-text-body;
  }
  .btn-primary-outlined svg {
    @apply fill-text-body;
  }
  .btn-secondary {
    @apply border-2 border-secondary bg-background-base text-secondary;
  }
  .btn-secondary svg {
    @apply fill-secondary;
  }
  .btn-error {
    @apply bg-error text-error-contrast;
  }
  .btn-error svg {
    @apply fill-error-contrast;
  }
  .btn-info {
    @apply bg-info text-info-contrast;
  }
  .btn-info svg {
    @apply fill-info-contrast;
  }
  .btn-info-outlined {
    @apply border-2 border-info bg-background-base text-text-body;
  }
  .btn-info-outlined svg {
    @apply fill-text-body;
  }
  .btn-outlined {
    @apply border border-background-form bg-background-base text-text-body2;
  }
  .btn-delete {
    @apply border border-error bg-primary-light-contrast text-error-contrast;
  }
  .btn-red-outlined {
    @apply border border-background-form bg-background-base text-red-700;
  }
  .btn-outlined svg {
    @apply fill-text-body2;
  }
  .btn-neutral {
    @apply bg-background-neutral;
  }
  .btn-neutral svg {
    @apply fill-text-body;
  }
  .btn-base {
    @apply bg-background-base;
  }
  .btn-base svg {
    @apply fill-text-body;
  }
  .btn-text {
    @apply border-transparent bg-background-base text-text-body hover:bg-background-neutral hover:opacity-100;
  }
  .btn-text svg {
    @apply fill-text-body;
  }
  .btn-input-value {
    @apply flex cursor-pointer items-center border-0 bg-info fill-info-contrast px-4 py-2 text-info-contrast hover:opacity-50;
  }
  .btn-input-value svg {
    @apply fill-info-contrast;
  }
  .input-value {
    @apply flex items-center border-0 bg-info px-4 py-2 text-info-contrast;
  }
  .btn-icon {
    @apply rounded border border-transparent p-2 hover:no-underline hover:opacity-50 disabled:cursor-not-allowed disabled:opacity-50;
  }
  .menu-button {
    @apply btn flex w-full items-center justify-between p-3 text-sm text-secondary-contrast transition-colors hover:bg-primary-light hover:no-underline hover:opacity-100;
  }
  .menu-button-active {
    @apply bg-primary-light text-primary-light-contrast;
  }
  .form-error {
    @apply text-[11px] italic text-error;
  }
  .input {
    @apply block w-full border border-background-form px-3 py-1.5 hover:border-secondary active:ring-secondary disabled:pointer-events-none;
  }
  .input-error {
    @apply border-error;
  }
  [type='checkbox'] {
    @apply h-6 w-6 rounded-sm border border-background-form;
  }
  [type='checkbox']:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23479F98' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    @apply h-6 w-6 rounded-sm border border-primary text-background-base;
  }

  .tooltip {
    @apply absolute bottom-[50px] z-10 min-w-[200px] rounded bg-background-contrast p-2 text-primary-contrast;
  }

  .tooltip-left {
    @apply right-[0];
  }

  .paper {
    @apply w-full shadow-paper;
  }

  .table-container {
    @apply w-full overflow-x-scroll rounded;
  }

  .table-container-without-shadow {
    @apply w-full overflow-x-scroll rounded;
  }

  .table-container table,
  .table-container-without-shadow table {
    @apply min-w-[650px] select-text;
  }

  .table-container table tr td a,
  .table-container-without-shadow table tr td a {
    @apply select-text;
  }

  .table-container table tr td,
  .table-container-without-shadow table tr td {
    @apply select-text py-2;
  }

  .table-container-without-shadow {
    @apply w-full overflow-x-scroll rounded  border border-border bg-white;
  }

  .table-container-without-shadow table {
    @apply min-w-[650px] select-text;
  }

  .table-container-without-shadow table tr td a {
    @apply select-text;
  }

  .table-container-without-shadow table tr td {
    @apply select-text py-2;
  }

  .tr-link {
    @apply hover:bg-background-form;
  }

  .td-link {
    @apply block px-4 py-3 hover:no-underline;
  }

  .sticky-first-column {
    @apply max-w-full overflow-x-scroll;
  }

  .sticky-first-column th,
  .sticky-first-column td {
    @apply whitespace-nowrap;
  }

  .sticky-first-column td:first-child,
  .sticky-first-column th:first-child {
    @apply sticky left-0;
  }

  .simple-table th {
    @apply select-text border-b-2 bg-background-neutral;
  }

  .simple-table td {
    @apply select-text border-b bg-background-base;
  }

  .entity-overview {
    @apply flex min-h-[275px] flex-col justify-between rounded-md bg-secondary p-6 text-secondary-contrast shadow-paper;
  }
  .entity-overview-no-shadow {
    @apply flex min-h-[275px] flex-col justify-between rounded-md bg-secondary p-6 text-secondary-contrast;
  }

  .entity-details {
    @apply rounded-md bg-background-neutral p-6 shadow-paper;
  }

  .entity-details-no-shadow {
    @apply rounded-md bg-white p-6;
  }

  .white-entity-details {
    @apply rounded-md border border-border bg-white p-6;
  }

  .ic-details {
    @apply rounded-md bg-white p-6 shadow-paper;
  }

  .two-column {
    @apply xl:grid xl:grid-cols-two-column-layout xl:gap-4;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .hidden-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .hidden-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .html-content {
    ul {
      @apply list-inside list-disc pl-4;
      ul {
        list-style-type: circle;
      }
    }
  }

  .measurement-table {
    th {
      @apply select-text border-b font-semibold;
    }

    tr {
      @apply select-text align-top;
    }

    tr td {
      @apply select-text bg-background-base;
    }

    tr:nth-child(odd) td:not(.measurement-title) {
      @apply bg-background-neutral;
    }
  }

  .measurement-title {
    @apply border-r;
  }

  .syn-totals-row tr:last-child {
    @apply sticky bottom-0;
  }

  .syn-totals-row tr:last-child td {
    @apply bg-syn-summary-totals-row text-[14px] font-bold text-primary;
  }

  .syn-totals-row tr:last-child td:before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    top: -1px;
    border-top: 1px solid #28746e;
  }

  .syn-totals-row tr:last-child td:first-child {
    @apply rounded-bl-[12px];
  }

  .syn-totals-row tr:last-child td:last-child {
    @apply rounded-br-[12px];
  }

  .syn-calendar::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
}
